// ** Initial State
const initialState = {
  allData: [],
  data: [],
  historyData : [],
  historytotal : [],
  total: 1,
  params: {},
  selectedUser: null,
  isEditModal : false,
  isError : false
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser[0] }
    case 'GET_USER_UPDATE':
      return { ...state, selectedUser: action.data }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    case 'EDIT_USER':
      return { ...state, isEditModal : action.data }
    case 'GET_History':
      return {
        ...state,
        historyData: action.historyData,
        historytotal : action.historytotalPages,
        params: action.params
      }
    case 'CHECK_ERROR':
      return { ...state, isError : true}
    default:
      return { ...state }
  }
}
export default users
