// ** Initial State
const initialState = {
    user_role : 'user',
    auth_user : null
}
const userAuth = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_USER_DATA':
            return { ...state, auth_user: action.data }
        case 'AUTH_USER_ROLE':
            return { ...state, user_role: action.data }
        default:
            return { ...state }
    }
}
export default userAuth