import mock from '../mock'
import {paginateArray} from '../utils'
import moment from "moment"
// ** axios
import {axiosInstance} from '@configs/axiosinstance.js'

const Log = (message, ...data) => console.log(message, ...data)

const FetchAnnouncement = () => {
    Log('axios:get', '/announcement')
    // return [200, data]
    return axiosInstance
        .get('/announcement')
        .then(response => {
            Log('axios:response', response)
            return response
        })
}
const sortData = (data, order) => {
    if (order === '1') {
         return data.reverse()
     } else {
         return  data
     }
}

// GET Updated DATA
mock.onGet('/announcement/list/data').reply(config => FetchAnnouncement().then(response => {
    const {data} = response
    Log('user-data', data)
    const {q = '', perPage = 10, page = 1, order = null, currentPlan = null, status = null} = config
    /* eslint-disable  */
    const queryLowered = q.toLowerCase()
    data.map((item) => {
        const CurrentDate = new Date().getTime()
        const start_date = new Date(item.start_date).getTime()
        const end_date = new Date(item.end_date).getTime()
        if (CurrentDate > start_date && CurrentDate > end_date) {
            item.status = "past"
        }
        if (CurrentDate >= start_date && CurrentDate <= end_date) {
            item.status = "active"
        }
        if (CurrentDate < start_date && CurrentDate < end_date) {
            item.status = "pending"
        }
        return item
    })
    const filteredData = data.filter(announcement => announcement.status !== undefined && announcement.status.toLowerCase().includes(status))
     const sorted = sortData(filteredData, order)
    /* eslint-enable  */
    return [
        200,
        {
            announcement : paginateArray(sorted, perPage, page),
            total: filteredData.length
        }
    ]
}))

//get filtered data
// GET Updated DATA
mock.onGet('/announcement/list/filtered').reply(config => FetchAnnouncement().then(response => {
    const {data} = response
    data.map((item) => {
        const CurrentDate = new Date().getTime()
        const start_date = new Date(item.start_date).getTime()
        const end_date = new Date(item.end_date).getTime()
        if (CurrentDate > start_date && CurrentDate > end_date) {
            item.status = "past"
        }
        if (CurrentDate >= start_date && CurrentDate <= end_date) {
            item.status = "active"
        }
        if (CurrentDate < start_date && CurrentDate < end_date) {
            item.status = "pending"
        }
        return item
    })
    return [
        200,
        {
            data
        }
    ]
}))
