import mock from '../mock'
// ** axiosInstance
import {axiosInstance} from '@configs/axiosinstance.js'
const Log = (message, ...data) => console.log(message, ...data)
const data = {
    // status for normal contact , friend status for friend, friend_request status for request is sent to you, request_sent is status for friend request is sent by you, block status for block friend, unfollow for unfollow friend and follow status for follow
    // notification list data
    friendRequestsArray: [
        {
            id:1,
            requester:{
                id: 2,
                fullName: 'Marjory Sicely',
                avatar: require('@src/assets/images/avatars/1.png').default,
                mutual_friends :2
            },
            recipient:1,
            status :'friend_request'  //0 for request yet not accepted and 1 for accepted
        },
        {
            id:2,
            requester:{
                id: 3,
                fullName: 'Jillene Bellany',
                avatar: require('@src/assets/images/avatars/10.png').default,
                mutual_friends :25
            },
            recipient:1,
            status :'friend_request'  //0 for request yet not accepted and 1 for accepted
        },
        {
            id:3,
            requester:{
                id: 4,
                fullName: 'Jonah Wharlton',
                avatar: require('@src/assets/images/avatars/2.png').default,
                mutual_friends :10
            },
            recipient:1,
            status :'friend'  //0 for request yet not accepted and 1 for accepted
        },
        {
            id:4,
            requester:{
                id: 5,
                fullName: 'Seth Hallam',
                avatar: require('@src/assets/images/avatars/3.png').default,
                mutual_friends :2
            },
            recipient:1,
            status :'friend_request'  //0 for request yet not accepted and 1 for accepted
        },
        {
            id:5,
            requester:{
                id: 6,
                fullName: 'Maximilianus Krause',
                avatar: require('@src/assets/images/avatars/4.png').default,
                mutual_friends :25
            },
            recipient:1,
            status :'friend_request'  //friend_request is just friend request yet not accepted and friend for accepted
        },
        {
            id:6,
            requester:{
                id: 7,
                fullName: 'Zsazsa McCleverty',
                avatar: require('@src/assets/images/avatars/5.png').default,
                mutual_friends :7
            },
            recipient:1,
            status :'friend' //friend_request is just friend request yet not accepted and friend for accepted
        },
        {
            id:7,
            requester:{
                id: 8,
                fullName: 'Marjory Sicely',
                avatar: require('@src/assets/images/avatars/6.png').default,
                mutual_friends :3
            },
            recipient:1,
            status :'friend_request' //friend_request is just friend request yet not accepted and friend for accepted
        },
        {
            id:8,
            requester:{
                id: 9,
                fullName: 'Marjory Sicely 2',
                avatar: require('@src/assets/images/avatars/8.png').default,
                mutual_friends :2
            },
            recipient:1,
            status :'friend_request'  //friend_request is just friend request yet not accepted and friend for accepted
        },
        {
            id:9,
            requester:{
                id: 10,
                fullName: 'Marjory Sicely 3',
                avatar: require('@src/assets/images/avatars/9.png').default,
                mutual_friends :5
            },
            recipient:1,
            status :'friend_request'  //friend_request is just friend request yet not accepted and friend for accepted
        },
        {
            id:10,
            requester:{
                id: 11,
                fullName: 'Marjory Sicely 4',
                avatar: require('@src/assets/images/avatars/11.png').default,
                mutual_friends :20
            },
            recipient:1,
            status :'friend_request'  //friend_request is just friend request yet not accepted and friend for accepted
        }
    ]
}
//followers record
const FetchFollowers = () => {
    return axiosInstance
        .get('/follow')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
const FetchFriend = () => {
    return axiosInstance
        .get('/friends/friend')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}

const RecentFriend = () => {
    return axiosInstance
        .get('/friends/recient')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
//get recent deleted
const RecentDeleted = () => {
    return axiosInstance
        .get('/friends/deleted')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}

//get only friend request
//get recent deleted
const onlyFriendRequest = () => {
    return axiosInstance
        .get('/friends/requests')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
// GET Updated DATA
mock.onGet('/data/friend/list').reply(config => FetchFriend().then(response => {
    const {data, friends} = response
    const followers = response.data.followers
    const followings = response.data.followings
         const map_data = data.data.map((item) => {
             const found = followers.find(user => user.recipient === item.user_id)
             if (found) {
                 item.follow_status = found.follow_status
             } else {
                 item.follow_status = 'follow_down'
             }
             return item
         })
         return [
             200,
             {
                 friends : map_data,
                 following:followings
             }
         ]
}))
mock.onGet('/data/friend/recent').reply(config => RecentFriend().then(response => {
    const {data} = response
    // const user = JSON.parse(localStorage.getItem('userData'))
    // const user_id = user.id
    // const map_data = data.data.map((item) => {
    //     if (item.requester[0]._id !== user_id) {
    //         item.friend = item.requester[0]
    //     }
    //     if (item.recipient[0]._id !== user_id) {
    //         item.friend = item.recipient[0]
    //     }
    //     return item
    // })
    /* eslint-enable  */
    Log('user friend data', data)
    return [
        200,
        {
            friends : data.data
        }
    ]
}))

//recent deleted requests
mock.onGet('/data/friend/deleted').reply(config => RecentDeleted().then(response => {
    const {data} = response
    // const user = JSON.parse(localStorage.getItem('userData'))
    // const user_id = user.id
    // const map_data = data.data.map((item) => {
    //     if (item.requester[0]._id !== user_id) {
    //         item.friend = item.requester[0]
    //     }
    //     if (item.recipient[0]._id !== user_id) {
    //         item.friend = item.recipient[0]
    //     }
    //     return item
    // })
    /* eslint-enable  */
    Log('user friend data', data)
    return [
        200,
        {
            friends : data.data
        }
    ]
}))
//get only friend request
//recent deleted requests
mock.onGet('/data/friends/requests').reply(config => onlyFriendRequest().then(response => {
    const {data} = response
    // const user = JSON.parse(localStorage.getItem('userData'))
    // const user_id = user.id
    // const map_data = data.data.map((item) => {
    //     if (item.requester[0]._id !== user_id) {
    //         item.friend = item.requester[0]
    //     }
    //     if (item.recipient[0]._id !== user_id) {
    //         item.friend = item.recipient[0]
    //     }
    //     return item
    // })
    /* eslint-enable  */
    Log('only friend  Request friend data', data)
    return [
        200,
        {
            friends : data.data
        }
    ]
}))
mock.onGet('/request/list').reply(() => {
    data.friendRequestsArray.filter((item, index) => {
        if (item.status === 'friend_request') {
            return item
        }
    })
    return [200, data.friendRequestsArray]
})
//delete friend request
mock.onGet('/delete/request').reply((config) => {
    const id = Number(config.id)
    const request  = data.friendRequestsArray.reduce((p, item) => (item.id !== id && p.push(item), p), [])
     data.friendRequestsArray = request
    return [200, data.friendRequestsArray]
})
//confirm friend request
mock.onGet('/confirm/request').reply((config) => {
    const id = Number(config.id)
    data.friendRequestsArray.filter((item, index) => {
        if (Number(item.id) === id) {
             item.status = 'friend'
        }
        return item
    })
    data.friendRequestsArray = data.friendRequestsArray
    return [200, data.friendRequestsArray]
})
mock.onGet('/user/statusUpdate').reply((config) => {
    const id = Number(config.id)
    const status = Number(config.status)

    data.friendRequestsArray.filter((item, index) => {
        if (Number(item.id) === id) {
            item.status = status
        }
        return item
    })
    return [200, data.friendRequestsArray]
})

//get followers
mock.onGet('/data/follow/list').reply(config => FetchFollowers().then(response => {
    const {data} = response
    Log('user-friend follow record', data)

    /* eslint-enable  */
    return [
        200,
        {
            data
        }
    ]
}))
