// ** Initial State
const initialState = {
  allSearchData: [],
  allSuggestionData : [],
  searchHistory : [],
  display_limit : 10
}

const SearchResults = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULT':
      return { ...state, allSearchData: action.data }
    case 'UPDATE_SEARCH_DATA':
      return { ...state, allSearchData: action.data }
    case 'GET_SEARCH_HISTORY':
      return { ...state, searchHistory: action.data }
    case 'SHOW_ALL_RESULTS':
      return { ...state, display_limit: action.data }
    default:
      return { ...state }
  }
}
export default SearchResults
