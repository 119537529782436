const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  selectedUser: {},
  apicontacts : [],
  apichats : [],
  apiSelectedUser : {},
  apiSelectedUserChat : [],
  socket : null,
  newMessageCount : 0
 }

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'GET_API_CHAT_CONTACTS':
      return { ...state, apicontacts: action.data}
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.newMessageData
      const newData = state.selectedUser
        newData.chat.chat.push(newMsg)
      return { ...state, selectedUser: newData}
    case 'SEND_SOCKET':
      return { ...state, socket: action.data }
    case 'SELECT_USER_CHAT':
      return { ...state,
        apiSelectedUser: action.data,
        chats: state.chats.map((item) => {
              if (item.conversation_id === action.data.conversation_id) {
                        item.new = 0
              }
              return item
        })}
    case 'SELECT_USER_CHA':
      return { ...state, apiSelectedUserChat: action.data}
    case 'CLEAR_USER_CHAT':
      const conv = state.chats.find((item) => item.id === action.contact_id)
        let contacts2 = []
        if (conv) {
          conv.lastMessage = ''
          contacts2 = [conv, ...state.contacts]
        } else {
          contacts2 = [...state.contacts]
        }
      return { ...state, apiSelectedUserChat: action.data, contacts : contacts2, chats : state.chats.filter((item) => item.id !== action.contact_id)}
    case 'SEND_CONVERSATION_MSG':
      return { ...state, apiSelectedUserChat: [...state.apiSelectedUserChat, action.data] }
    case 'COUNT_CONVERSATION_NEW_MSG_2':
      return { ...state,
        chats: state.chats.map((item) => {
           if (item.conversation_id === action.data.conversation_id) {
              if (action.data.type === 'text') {
                item.lastMessage = action.data.message
              } else {
                item.lastMessage = 'file.....'
              }
             // if (item.lastMessage !== action.data.message) {
                item.new = parseInt(item.new) + 1
              //}
           }
          if (state.apiSelectedUser.conversation_id === item.conversation_id) {
             item.new = 0
          }

           return item
        }),
        contacts: state.contacts.map((item) => {
          if (item.conversation_id === action.data.conversation_id) {
            if (action.data.type === 'text') {
              item.lastMessage = action.data.message
            } else {
              item.lastMessage = 'file.....'
            }
             if (item.new) {
                         item.new = parseInt(item.new) + 1
             } else {
               item.new =  1
             }
          }
          if (state.apiSelectedUser.conversation_id === item.conversation_id) {
            item.new = 0
          }
          return item
        })
      }
    case 'MAKE_COUNT_CONVERSATION_NEW_MSG_0':
      return { ...state,
        chats: state.chats.map((item) => {
          if (item.conversation_id === action.data) {
            item.new = 0
          }
          return item
        }),
        contacts: state.contacts.map((item) => {
          if (item.conversation_id === action.data) {
            item.new = 0
          }
          return item
        })
      }
    case 'RECEIVE_CONVERSATION_MESSAGE':
      let chat = []
        if (state.apiSelectedUser.conversation_id === action.data.conversation_id) {
          const found = state.apiSelectedUserChat.find(chat => chat._id === action.data._id)
          if (!found) {
            chat = [...state.apiSelectedUserChat, action.data]
          } else {
            chat = [...state.apiSelectedUserChat]
          }
        } else {
          chat = [...state.apiSelectedUserChat]
        }
      return { ...state, apiSelectedUserChat: chat}
    case 'JOIN_NEW_USERS':
      const online_users = action.data
      return { ...state,
        contacts: state.contacts.map((item) => {
          const found = online_users.find(chat => chat.user_id === item.id)
          if (found) {
            item.status = 'online'
          } else {
            item.status = 'offline'
          }
        return item
        }),
        chats: state.chats.map((item) => {
          const found = online_users.find(chat => chat.user_id === item.id)
          if (found) {
            item.status = 'online'
          } else {
            item.status = 'offline'
          }
          return item
        })
      }
    case 'LEAVE_USER':
      const left_user = action.data
      return { ...state,
        contacts: state.contacts.map((item) => {
          if (left_user !== undefined && left_user.user_id === item.id && left_user.user_id !== null) {
            item.status = 'offline'
          }
          return item
        }),
        chats: state.chats.map((item) => {
          if (left_user !== undefined && left_user.user_id === item.id) {
            item.status = 'offline'
          }
          return item
        })}
    case 'SELECTED_USER_ACTIVE':
      return { ...state, apiSelectedUser: action.data}
    case 'RE_ORDER_CONTACTS':
         let orderContact = []
         if (state.chats.length > 0) {
           orderContact = state.chats.sort((a, b) => b.new - a.new)
         }
      return { ...state,  chats: orderContact}
    case 'MESSAGE_COUNT':
      return { ...state, newMessageCount: action.data}
    default:
      return state
  }
}

export default chatReducer
