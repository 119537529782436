import mock from '../mock'

export const searchArr = {
    // status for normal contact , friend status for friend, friend_request status for request is sent to you, request_sent is status for friend request is sent by you, block status for block friend, unfollow for unfollow friend and follow status for follow
    search_results : [
                {
                    id: 1,
                    fullName: 'Felecia Rower',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
                    user_status: 'offline',
                    mutual_friends :5,
                    status : 'user'
                },
                {
                    id: 2,
                    fullName: 'Marjory Sicely',
                    avatar: require('@src/assets/images/avatars/1.png').default,
                    request_id :1,
                    user_status: 'busy',
                    mutual_friends :15,
                    status : 'friend_request'
                },
                {
                    id: 3,
                    fullName: 'Jillene Bellany',
                    avatar: require('@src/assets/images/avatars/10.png').default,
                    request_id :2,
                    user_status: 'busy',
                    mutual_friends :10,
                    status : 'friend_request'
                },
                {
                    id: 4,
                    fullName: 'Verla Morgano',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
                    user_status: 'online',
                    status : 'friend_request'
                },
                {
                    id: 5,
                    fullName: 'Seth Hallam',
                    avatar: require('@src/assets/images/avatars/3.png').default,
                    request_id :4,
                    user_status: 'busy',
                    mutual_friends :1,
                    status : 'friend_request'
                },
                {
                    id: 6,
                    fullName: 'Sal Piggee',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
                    user_status: 'online',
                    mutual_friends :8,
                    status : 'friend'
                },
                {
                    id: 7,
                    fullName: 'Miguel Guelff',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
                    user_status: 'online',
                    mutual_friends :2,
                    status : 'user'
                },
                {
                    id: 8,
                    fullName: 'Mauro Elenbaas',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
                    user_status: 'away',
                    mutual_friends :1,
                    status : 'user'
                },
                {
                    id: 9,
                    fullName: 'Bridgett Omohundro',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
                    user_status: 'offline',
                    mutual_friends :0,
                    status : 'user'
                },
                {
                    id: 10,
                    fullName: 'Zenia Jacobs',
                    avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
                    user_status: 'away',
                    mutual_friends :12,
                    status : 'friend'
                }
    ],
    search_history : [
        {
            id : 1,
            title : 'James James'
        },
        {
            id : 2,
            title : 'James Jon'
        },
        {
            id : 3,
            title : 'Jon Jon'
        },
        {
            id : 4,
            title : 'Jack chan'
        },
        {
            id : 5,
            title : 'Muhammad'
        }
    ]
}
// GET Search Data
mock.onGet('/search/results').reply(config => {
    return [200, searchArr.search_results]
})
mock.onGet('/search/history').reply(() => {
    return [200, searchArr.search_history]
})
mock.onGet('/update/search/result').reply((config) => {
    const  source_id = config.id
    searchArr.search_results.map((item, index)  => {
        if (item.id === source_id) {
            item.status = 'friend'
        }
        return item
    })
    return [200, searchArr.search_history]
})
mock.onGet('/send/friend/request').reply((config) => {
    const  source_id = config.id
    searchArr.search_results.map((item, index)  => {
        if (item.id === source_id) {
            item.status = 'request_sent'
        }
        return item
    })
    return [200, searchArr.search_history]
})
