// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedPost: null,
  isEditModal : false,
  keywordsParams: {},
  keywordsData : [],
  keywordsAllData : [],
  total_keywords : 1,
  selectedKeyWord: '',
  allApprovedData: [],
  approvedData : [],
  totalApproved: 1,
  approved_params: {},
  loading : true
}
const defaultInitialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedPost: null,
  isEditModal : false,
  keywordsParams: {},
  keywordsData : [],
  keywordsAllData : [],
  total_keywords : 1,
  selectedKeyWord: '',
  allApprovedData: [],
  approvedData : [],
  totalApproved: 1,
  approved_params: {},
  loading : true
}

const posts = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_POST':
      return { ...state, selectedPost: action.selectedPost }
    case 'ADD_POST_FLAGS':
      return { ...state, data: state.data.filter((item) => item.id !== action.data) }
    case 'GET_KEYWORD_DATA':
      return {
        ...state,
        keywordsData: action.data,
        total_keywords: action.totalPages,
        keywordsAllData: action.allData,
        keywordsParams: action.params
      }
    case 'EDIT_KEYWORD_MODAL':
      return { ...state, isEditModal: action.data }
    case 'SELECTED_KEYWORD':
      return { ...state, selectedKeyWord: action.data }
    case 'GET_ALLAPPROVED_DATA':
      return { ...state, allApprovedData: action.data }
    case 'GET_APPROVED_DATA':
      return {
        ...state,
        approvedData: action.data,
        totalApproved: action.totalPages,
        approved_params: action.params
      }
    case 'DELETE_DATA':
      return { ...state, data: state.data.filter((item) => item.id !== action.data) }
    case 'RESET_ALL_DATA':
      return { ...state, ...defaultInitialState}
    case 'IS_LOADING_RESET':
      return { ...state, loading: action.data }
    default:
      return { ...state }
  }
}
export default posts
