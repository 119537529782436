// ** Initial State
const initialState = {
  friendRequests: [],
  apiFriendRequests:[],
  followers : [],
  friends : [],
  alldata:[],
  recentFriends : [],
  recentDeletedRequests : []
}

const friends = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_FRIEND_REQUEST':
      return { ...state, alldata: action.data }
    case 'API_ALL_FRIEND_REQUEST':
      return { ...state, apiFriendRequests: action.data }
    case 'DELETE_FRIEND_REQUEST':
      return { ...state,
        apiFriendRequests: state.apiFriendRequests.map(item => {
          if (item._id !==  action.data) {
            return item
          }
          return {
            ...item,
            status : 'deleted'
          }
        }) }
    case 'ADD_FRIEND':
      return {...state,
        friends : [
          ...state.friends,
          state.apiFriendRequests.some(user => user.id === action.data)
        ] }
    case 'API_CONFIRM_FRIEND_REQUEST':
      return {...state,
        apiFriendRequests: state.apiFriendRequests.filter(item => {
          if (item._id !==  action.data) {
            return item
          }
        }) }
    case 'STATUS_UPDATE':
      return { ...state,
        friends: state.friends.filter(item => {
          if (item._id !==  action.data.friend_request_id) {
            return item
          }
        })
      }
    case 'FOLLOW_STATUS_UPDATE':
      return { ...state,
        apiFriendRequests: state.apiFriendRequests.map(item => {
          if (item._id !==  action.data.friend_request_id) {
            return item
          }
          return {
            ...item,
            follow_status : action.data.status
          }
        })
      }
    case 'FOLLOWERS':
      return { ...state, followers:action.data}
    case 'FRIEND':
      return { ...state, friends:action.data}
    case 'RECIENT_FRIEND':
      return { ...state, recentFriends:action.data}
    case 'RECIENT_DELETED_REQUESTS':
      return { ...state, recentDeletedRequests:action.data}
    default:
      return { ...state }
  }
}
export default friends
