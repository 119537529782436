import mock from '../mock'
// ** axiosInstance
import {axiosInstance} from '@configs/axiosinstance.js'
/*eslint-disable */
const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)
const Log = (message, ...data) => console.log(message, ...data)
const data = {
  profileUser: {
    id: 11,
    avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
    fullName: 'John Doe',
    role: 'admin',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  },
  contacts: [
  ],
  chats: [
  ]
}

//get friends
const FetchFriend = () => {
  return axiosInstance
      .get('/friends/chat')
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
/*eslint-enable */
// ------------------------------------------------
// GET: Return Chats Contacts and Contacts
// ------------------------------------------------

//friends only
mock.onGet('/data/chat/chats-and-contacts').reply(() => FetchFriend().then(response => {
  //new code for chat
  data.contacts = []
  data.chats = []
    const user = JSON.parse(localStorage.getItem('userData'))
    const user_role = user.role
    if (user_role !== 'founder') {
        const map_data = response.data.data.filter((item) => (item.status === 'friend' || item.user_role === 'founder') && item.user_id !== undefined).map((item) => {
            const found = response.data.conversations.find(con => con.friend_id === item.user_id)
            if (found) {
                item.conversations = found.conversation_id
                item.creatd_at = found.created_at
            } else {
                item.conversations = ""
            }
            const fav = response.data.favourates.find(con => con.receiver === item.user_id)
            if (fav) {
                item.favourate = true
            } else {
                item.favourate = false
            }
            if (item.conversations !== "") {
                let lastmessage = ''
                const new_con = found.new_message
                if (found.messages.length > 0) {
                    const convers = found.messages
                    const   last_conversation = convers[found.messages.length - 1]
                    if (last_conversation.type === 'text') {
                        lastmessage = last_conversation.message
                    } else {
                        lastmessage = 'file.......'
                    }

                }

                if (lastmessage !== '') {
                    data.chats.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : item.conversations, lastMessage : lastmessage, created_at : found.messages.length > 0 ? found.messages[found.messages.length - 1].created_at : item.creatd_at, new: new_con, active_privacy : item.user_active_privacy, favourate :item.favourate})
                } else {
                    data.contacts.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : item.conversations, created_at : item.creatd_at, active_privacy : item.user_active_privacy, favourate :item.favourate})
                }

            } else {
                data.contacts.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : '', created_at : item.creatd_at, active_privacy : item.user_active_privacy, favourate :item.favourate})
            }
            return item
        })
        const sortChatContacts = data.chats.map((chat) => {
            const date = new Date(chat.created_at)
            const seconds = date.getTime()
            chat.seconds = seconds
            return chat
        })
        return [200, {chatsContacts : sortChatContacts.sort((a, b) => b.seconds - a.seconds), contacts: data.contacts}]
    } else {
        const map_data = response.data.data.filter((item) => item.user_id !== undefined).map((item) => {
            const found = response.data.conversations.find(con => con.friend_id === item.user_id)
            if (found) {
                item.conversations = found.conversation_id
                item.creatd_at = found.created_at
            } else {
                item.conversations = ""
            }
            const fav = response.data.favourates.find(con => con.receiver === item.user_id)
            if (fav) {
                item.favourate = true
            } else {
                item.favourate = false
            }
            if (item.conversations !== "") {
                let lastmessage = ''
                const new_con = found.new_message
                if (found.messages.length > 0) {
                    const convers = found.messages
                    const   last_conversation = convers[found.messages.length - 1]
                    if (last_conversation.type === 'text') {
                        lastmessage = last_conversation.message
                    } else {
                        lastmessage = 'file.......'
                    }

                }
                if (lastmessage !== '') {
                    data.chats.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : item.conversations, lastMessage : lastmessage, created_at : found.messages.length > 0 ? found.messages[found.messages.length - 1].created_at : item.creatd_at, new: new_con, active_privacy : item.user_active_privacy, favourate :item.favourate})
                } else {
                    data.contacts.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : item.conversations, created_at : item.creatd_at, active_privacy : item.user_active_privacy, favourate :item.favourate})
                }

            } else {
                data.contacts.push({id: item.user_id, fullName: item.friend_name, avatar : item.profile_photo  ? item.profile_photo : '', status: 'offline', role: 'Building surveyor', about : '', conversation_id : '', created_at : item.creatd_at, active_privacy : item.user_active_privacy, favourate :item.favourate})
            }
            return item
        })
        const sortChatContacts = data.chats.map((chat) => {
            const date = new Date(chat.created_at)
            const seconds = date.getTime()
            chat.seconds = seconds
            return chat
        })
        return [200, {chatsContacts : sortChatContacts.sort((a, b) => b.seconds - a.seconds), contacts: data.contacts}]
    }

}))

//get friend chat
//get friends
const FetchFriendChat = (config) => {
  return axiosInstance
      .post('/friends/messages', config)
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
mock.onPost('/data/chat/get-chat').reply((config) => FetchFriendChat(config).then(response => {
  //new code for chat
  return [200, {contacts: []}]
}))

mock.onGet('/apps/chat/chats-and-contacts').reply(()  => {
  const chatsContacts = data.chats.map(chat => {
    const contact = data.contacts.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })
  const profileUserData = {
    id: data.profileUser.id,
    avatar: data.profileUser.avatar,
    fullName: data.profileUser.fullName,
    status: data.profileUser.status
  }
  return [200, { chatsContacts, contacts: data.contacts, profileUser: profileUserData }]
})

// ------------------------------------------------
// GET: Return User Profile
// ------------------------------------------------
mock.onGet('/apps/chat/users/profile-user').reply(() => [200, data.profileUser])

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
const getMessage = (config) => {
  return axiosInstance
      .get(`/friends/gets/messages/${config.id}`)
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
mock.onGet('/apps/chat/get-chat').reply((config) => getMessage(config).then(response2 => {
  // Get event id from URL
  const messages_data = response2.data.data
  let userId = config.id
  //  Convert Id to number
  userId = parseInt(userId)
  const checkChat = data.chats.find(c => c.userId === userId)
  if (!checkChat) {
       const chat_id = Number(data.chats.length) + 1
       const chat_obj  = {id:userId, userId, unseenMsgs:0, lastMessage:'', chat : []}
       data.chats.push(chat_obj)
  }

  const chat  = data.chats.find(c => parseInt(c.userId) === userId)
  if (chat) chat.unseenMsgs = 0
  if (messages_data.length > 0) {
    chat.chat = []
    messages_data.map((item) => {
      chat.chat.push({
        message: item.message,
        time: item.created_at,
        senderId: item.sender
      })
    })
  } else {
    chat.chat = []
  }
  const contact = data.contacts.find(c => c.id === config.id)
  if (contact.chat) contact.chat.unseenMsgs = 0
  return [200, { chat, contact }]
}))


// ------------------------------------------------
// POST: Add new chat message
// ------------------------------------------------
// mock.onPost('/apps/chat/send-msg').reply(config => {
//   // Get event from post data
//   const { obj } = JSON.parse(config.data)
// console.log(obj)
//   let activeChat = data.chats.find(chat => chat.userId === obj.contact.id)
//
//   const newMessageData = {
//     message: obj.message,
//     time: new Date(),
//     senderId: 11
//   }
//   // If there's new chat for user create one
//   let isNewChat = false
//   if (activeChat === undefined) {
//     isNewChat = true
//
//     const { length } = data.chats
//     // const lastId = data.chats[length - 1].id
//
//     data.chats.push({
//       id: obj.contact.id,
//       userId: obj.contact.id,
//       unseenMsgs: 0,
//       chat: [newMessageData]
//     })
//     activeChat = data.chats[data.chats.length - 1]
//   } else {
//     activeChat.chat.push(newMessageData)
//   }
//
//   const response = { newMessageData, id: obj.contact.id }
//   if (isNewChat) response.chat = activeChat
//   return [201, { response : []}]
//  // return [201, { response }]
// })
//send message
const sendMessage = (config) => {
  return axiosInstance
      .post('/friends/sendmessages', JSON.parse(config.data))
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
mock.onPost('/data/chat/send-msg').reply((config) => sendMessage(config).then(response2 => {
  const { obj } = JSON.parse(config.data)
  let activeChat = data.chats.find(chat => parseInt(chat.userId) === parseInt(obj.contact.id))
  const user = JSON.parse(localStorage.getItem('userData'))
  const user_id = user.id
  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: user_id
  }
  // If there's new chat for user create one
  let isNewChat = false
  if (activeChat === undefined) {
    isNewChat = true

    const { length } = data.chats
     const lastId = data.chats[length - 1].id

    data.chats.push({
      id: obj.contact.id,
      userId: obj.contact.id,
      unseenMsgs: 0,
      chat: [newMessageData]
    })
    activeChat = data.chats[data.chats.length - 1]
  } else {
    activeChat.chat.push(newMessageData)
  }

  const response = { newMessageData, id: obj.contact.id }
  if (isNewChat) response.chat = activeChat
  return [201, { response }]
}))
const getNewMessage = (config) => {
  return axiosInstance
      .get(`/friends/new/messages/${config.id}`)
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
mock.onGet('/apps/new/get-chat').reply((config) => getNewMessage(config).then(response2 => {
  // Get event id from URL
  const messages_data = response2.data.data
  let userId = config.id
  //  Convert Id to number
  userId = parseInt(userId)
  const checkChat = data.chats.find(c => c.userId === userId)
  if (!checkChat) {
    const chat_id = Number(data.chats.length) + 1
    const chat_obj  = {id:userId, userId, unseenMsgs:0, lastMessage:'', chat : []}
    data.chats.push(chat_obj)
  }

  const chat  = data.chats.find(c => parseInt(c.userId) === userId)
  if (chat) chat.unseenMsgs = 0
  if (messages_data.length > 0) {
   // chat.chat = []
    messages_data.map((item) => {
      chat.chat.push({
        message: item.message,
        time: item.created_at,
        senderId: item.sender
      })
    })
  }
  const contact = data.contacts.find(c => c.id === config.id)
  if (contact.chat) contact.chat.unseenMsgs = 0
  return [200, { chat, contact }]
}))

//get friends
const FetchOldFriendChat = (config) => {
 const data = JSON.parse(config.data)
  return axiosInstance
      .get(`/friends/old/messages/${data.id}/${data.conversation_id}`, config)
      .then(response => {
        Log('axiosInstance:response', response)
        return response
      })
}
mock.onPost('/data/old/chat/get-chat').reply((config) => FetchOldFriendChat(config).then(response => {
  return [200, { conversation : response.data.conversation, messages: response.data.messages, profile : response.data.profile}]
}))