// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/admin/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import notifications from '@src/views/notifications/store/reducer'
import friends from '@src/views/friends/store/reducer'
import SearchResults from '@src/views/SearchBar/store/reducer'
import profile from '@src/views/profile/store/reducer'
import announcement from "../../views/admin/announcement/store/reducer"
import posts from "../../views/admin/posts/store/reducer"
import userAuth from "../../views/auth/store/reducer"
import news from '@src/views/apps/newsletter/store/reducer'
const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  notifications,
  friends,
  SearchResults,
  profile,
  announcement,
  posts,
  userAuth,
  news
})

export default rootReducer
