import mock from '../mock'

const data = {
        news: [
            {
                id:1,
                type:'image',
                status:'active',
                avatar: require('@src/assets/images/portrait/small/avatar-s-18.jpg').default,
                username: 'Leeanna Alvord',
                postTime: 'about 1 minutes ago Public',
                postText: 'This is my new post.',
                postImg: require('@src/assets/images/profile/post-media/25.jpg').default,
                likes: 1240,
                youLiked: true,
                comments: '210 Comments',
                share: 'Share',
                likedUsers: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
                        username: 'Trine Lynes'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
                        username: 'Lilian Nenes'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Alberto Glotzbach'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
                        username: 'George Nordic'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
                        username: 'Vinnie Mostowy'
                    }
                ],
                likedCount: 140,
                detailedComments: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
                        username: 'Kitty Allanson',
                        comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                        commentsLikes: 34,
                        youLiked: false
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
                        username: 'Jackey Potter',
                        comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                        commentsLikes: 61,
                        youLiked: true
                    }
                ]
            },
            {
                id:2,
                type:'image',
                status:'active',
                avatar: require('@src/assets/images/portrait/small/avatar-s-18.jpg').default,
                username: 'Leeanna Alvord',
                postTime: 'about 2 minuts ago Public',
                postText:
                    'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
                postImg: require('@src/assets/images/profile/post-media/2.jpg').default,
                likes: 1240,
                youLiked: true,
                comments: '210 Comments',
                share: 'Share',
                likedUsers: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
                        username: 'Trine Lynes'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
                        username: 'Lilian Nenes'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Alberto Glotzbach'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
                        username: 'George Nordic'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
                        username: 'Vinnie Mostowy'
                    }
                ],
                likedCount: 140,
                detailedComments: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
                        username: 'Kitty Allanson',
                        comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                        commentsLikes: 34,
                        youLiked: false
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
                        username: 'Jackey Potter',
                        comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                        commentsLikes: 61,
                        youLiked: true
                    }
                ]
            },
            {
                id:3,
                status:'active',
                type:'video',
                avatar: require('@src/assets/images/portrait/small/avatar-s-22.jpg').default,
                username: 'Rosa Walters',
                postTime: 'about 12 minuts ago Public',
                postText:
                    'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
                postImg: require('@src/assets/images/profile/post-media/25.jpg').default,
                likes: 1240,
                youLiked: true,
                comments: '200 Comments',
                share: 'Share',
                likedUsers: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
                        username: 'Kori Scargle'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
                        username: 'Florinda Mollison'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Beltran Endley'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
                        username: 'Kara Gerred'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
                        username: 'Sophey Bru'
                    }
                ],
                likedCount: 271,
                detailedComments: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Kitty Allanson',
                        comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                        commentsLikes: 34,
                        youLiked: false
                    }
                ]
            },
            {
                id:4,
                status:'in-active',
                type:'video',
                avatar: require('@src/assets/images/portrait/small/avatar-s-15.jpg').default,
                username: 'Charles Watson',
                postTime: 'about 20 minuts ago Public',
                postText:
                    'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
                postVid: 'https://www.youtube.com/embed/6stlCkUDG_s',
                likes: 1240,
                youLiked: true,
                comments: '180 Comments',
                share: 'Share',
                likedUsers: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
                        username: 'Dehlia Bolderson'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
                        username: 'De Lamy'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Vallie Kingsley'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
                        username: 'Nadia Armell'
                    },
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
                        username: 'Romonda Aseef'
                    }
                ],
                likedCount: 264,
                detailedComments: [
                    {
                        avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
                        username: 'Kitty Allanson',
                        comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                        commentsLikes: 34,
                        youLiked: false
                    }
                ]
            }
        ]
    }

mock.onGet('/news').reply(() => {
    return [200, data.news]
})

mock.onPut('/user/profile/update22').reply((config) => {
    const formdata = JSON.parse(config.data)
    const user_data = formdata.user_profile
    data.profileData.privacy.location = user_data.locPrivacy
    data.profileData.privacy.bio = user_data.userBioPrivacy
    data.profileData.header.avatar = user_data.userAvatar
    data.profileData.header.coverImg = user_data.userCovephoto
    data.profileData.userAbout.lives = user_data.loc
    data.profileData.userAbout.about = user_data.bio

    return [200, data.profileData]
})

mock.onPut('/user/profile/photo22').reply((config) => {
    const formdata = JSON.parse(config.data)
    const user_data = formdata.user_profile
    data.profileData.header.avatar = user_data.userAvatar

    return [200, data.profileData]
})
