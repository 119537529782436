// ** Initial State
const initialState = {
  allData: [],
  display_limit : 10,
  drop_down_limit : 10,
  drop_down_friend_limit:5,
  apiNotifications : []
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NOTIFICATION':
      return { ...state, allData: action.data }
    case 'API_ALL_NOTIFICATION':
      return { ...state, apiNotifications: action.data }
    case 'UPDATE_NOTIFICATION':
      return { ...state, allData: action.data }
    case 'CLEAR_NOTIFICATION':
      return { ...state, allData: action.data }
    case 'REMOVE_FRIEND_REQUEST_NOTIFICATION':
      return { ...state,
        apiNotifications: state.apiNotifications.filter(item => {
          if (item.source_id !==  action.data) {
            return item
          }
        }) }
    case 'SHOW_ALL_NOTIFICATION':
      return { ...state, display_limit: action.data }
    case 'SINGLE_UPDATE_NOTIFICATION':
      return { ...state,
        apiNotifications: state.apiNotifications.map(item => {
          if (item._id !==  action.data) {
            return item
          }
          return {
            ...item,
            notification_status: 'seen'
          }
        }) }
    case 'ADD_NEW_NOTIFICATION':
      return { ...state, apiNotifications: [action.data, ...state.apiNotifications] }
    default:
      return { ...state }
  }
}
export default notifications
