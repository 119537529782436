// ** Initial State
const initialState = {
    featuredVideo : null,
profileData : [],
featuredVideo : null,
posts : [],
news : [],
notification_privacy : [],
userProfile : [],
apiPosts : [],
comments : [],
contacts : [],
postDetail : [],
apiuserProfile : [],
userObj : [],
postmultimedia: [],
following : 0,
keywords : [],
userRestrictions : null,
commentRes: null,
user_role : '',
suggestionFriends : [],
public_user : {
    posts : [],
    friends : [],
    following : 0,
    followers : [],
    multimedia : [],
    profile : {},
    relation : {}
},
public_user_id : '',
tagsPosts : [],
tag : '',
    loadMore : true,
    skipRecord : 0,
    initialRecord : 20,
    publicloadMore : true,
    publicskipRecord : 0,
    limit:20,
    is_proccess : true

}
const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_PROFILE_DATA':
      return { ...state, profileData: action.data }
    case 'UPDATE_USER_PROFILE':
      return { ...state, profileData: action.data }
    case 'UPDATE_USER_PROFILE_PHOTO':
      return { ...state, profileData: action.data }
      case 'UPDATE_USER_PROFILE_POLICY':
          return { ...state, profileData: action.data }
    case 'POST_DATA':
      return { ...state, posts: action.data }
      case 'TAG_POST':
          return { ...state, tagsPosts: action.data }
      case 'DELETE_DATA':
          return {...state,
              apiPosts: state.apiPosts.filter(item => {
                  if (item._id !==  action.data) {
                      return item
                  }
              }) }
      case 'DELETE_COMMENT_DATA':
          return {...state,
              apiPosts: state.apiPosts.filter(item => {
                  // if (item._id !==  action.data) {
                  //     return item
                  // }
                  if (item.detailedComments) {
                      return {
                          ...item,
                          detailedComments :  item.detailedComments.filter(item2 => {
                          if (item2._id !== action.data) {
                              return item2
                          }
                      })
                      }
                  }
              }) }
    case 'NEWS_DATA':
      return { ...state, news: action.data }

    case 'USER_NOTIFICATION_PRICAY':
      return { ...state, notification_privacy: action.data }
      case 'USER_PROFILE':
          return { ...state, userProfile: action.data }
    case 'API_POST_DATA':
          return { ...state, apiPosts: [...state.apiPosts, ...action.data] }
      case 'PUBLISH_POST_DATA':
          return { ...state, apiPosts: [action.data,  ...state.apiPosts]}
      case 'ADD_LIKE':
          return { ...state,
              apiPosts: state.apiPosts.map(item => {
                  if (item._id !==  action.data) {
                      return item
                  }
                  return {
                      ...item,
                      likesCount: item.likesCount ? item.likesCount + 1 : 1,
                      youLiked : true
                  }
              }) }
      case 'ADD_COMMENT_LIKE':
          return { ...state,
              apiPosts: state.apiPosts.map(item => {
                  if (item._id !==  action.data) {
                      return item
                  }
                  if (item.detailedComments) {
                      return {
                          ...item,
                          likeCommentCount: item.detailedComments.likesCount ? item.detailedComments.likesCount + 1 : 1,
                          youLikedComment : true,
                          detailedComments : [...item.detailedComments, action.data]
                      }
                  } else {
                      return {
                          ...item,
                          detailedComments : [action.data]
                      }
                  }
                  return {
                      ...item,
                      likesCount: item.likesCount ? item.likesCount + 1 : 1,
                      youLiked : true
                  }
              })
          }
      case 'PUBLISH_COMMENT_DATA':
          return { ...state,
              apiPosts: state.apiPosts.map(item => {
                  if (item._id !==  action.data.post_id) {
                      return item
                  }
                  if (item.detailedComments) {
                      return {
                          ...item,
                          detailedComments : [...item.detailedComments, action.data]
                      }
                  } else {
                      return {
                          ...item,
                          detailedComments : [action.data]
                      }
                  }

              })
          }
      case 'USER_CONTACTS':
          return { ...state, contacts: action.data}
      case 'VIEW_POST':
          return { ...state, postDetail: action.data}
      case 'GET_USER':
          return { ...state, userObj: action.selectedUser}
      case 'UPDATE_POST':
          return { ...state,
              apiPosts: state.apiPosts.map(item => {
                  if (item._id ===  action.data._id) {
                      return  action.data
                  } else {
                      return  item
                  }
              })
          }
      case 'Remove_Contact':
          return { ...state,
              contacts: state.contacts.filter((item) => item._id !==  action.data)}
      case 'API_USER_PROFILE_DATA':
          return { ...state, apiuserProfile: action.data}
      case 'POST_MULTIMEDIA':
          return { ...state, postmultimedia: action.data}
      case 'REMOVE_USER_CONTACTS_SUGGESTION':
          return { ...state,
              contacts: state.contacts.map(item => {
                  if (item._id ===  action.data) {
                      item.contact_status = 'removed'
                  }
                  return item
              })}
      case 'FOLLOWING_COUNT':
          return { ...state, following: action.data}
      case 'UN_LIKE':
          return { ...state,
              apiPosts: state.apiPosts.map(item => {
                  if (item._id !==  action.data) {
                      return item
                  }
                  return {
                      ...item,
                      likesCount: item.likesCount ? item.likesCount - 1 : 1,
                      youLiked : false
                  }
              }) }
      case 'BANNED_KEYWORD_ARRAY':
          return { ...state, keywords : action.data}
      case 'USER_RESTRICTIONS':
          return { ...state, userRestrictions : action.data, commentRes : action.restrictions}
      case 'USER_ROLE':
          return { ...state, user_role : action.data}
      case 'USER_YOUKNOW':
          return { ...state, suggestionFriends: action.data}
      case 'REMOVE_KNOWN_CONTACTS_SUGGESTION':
          return { ...state,
              suggestionFriends: state.suggestionFriends.map(item => {
                  if (item._id ===  action.data) {
                      item.contact_status = 'removed'
                  }
                  return item
              })}
      case 'Remove_Known_Contact':
          return { ...state,
              suggestionFriends: state.suggestionFriends.map(item => {
                  if (item._id ===  action.data) {
                      item.contact_status = 'requested'
                  }
                  return item
              })}
      case 'PUBLIC_USER':
          return { ...state, public_user : {posts : [...state.public_user.posts, ...action.posts], friends : action.friends, profile : action.profile, followers : action.followers, following : action.following, multimedia : action.multimedia, relation: action.relation}}
      case 'PUBLIC_USER_ID':
          return { ...state, public_user_id : action.data}
      case 'TAG':
          return { ...state, tag: action.data}
      case 'IS_LOAD_MORE':
          return { ...state, loadMore: action.data }
      case 'SKIP_RECORD':
          return { ...state, skipRecord: action.data }
      case 'UPDATE_POST_DATA':
          return { ...state, apiPosts: [] }
      case 'PUBLIC_IS_LOAD_MORE':
          return { ...state, publicloadMore: action.data }
      case 'PUBLIC_SKIP_RECORD':
          return { ...state, publicskipRecord: action.data }
      case 'INT_API_POST_DATA':
          return { ...state, apiPosts: [] }
      case 'INT_API_PUBLIC_POST_DATA':
          return { ...state, public_user : { posts : [], friends : [], following : 0, followers : [], multimedia : [], profile : {}, relation : {}}}
      case 'IS_PROCCESS':
          return { ...state, is_proccess: action.data }
      case 'PUBLIC_UN_LIKE':
          return { ...state,
              public_user: {
              posts: state.public_user.posts.map(item => {
                  if (item._id !==  action.data) {
                      return item
                  }
                  return {
                      ...item,
                      likesCount: item.likesCount ? item.likesCount - 1 : 1,
                      youLiked : false
                  }
                  }),
                  friends: state.public_user.friends,
                  following: state.public_user.following,
                  followers: state.public_user.followers,
                  multimedia: state.public_user.multimedia,
                  profile: state.public_user.profile,
                  relation: state.public_user.relation}
          }
      case 'PUBLIC_LIKE_POST':
          return { ...state,
              public_user: {
                  posts: state.public_user.posts.map(item => {
                      if (item._id !==  action.data) {
                          return item
                      }
                      return {
                          ...item,
                          likesCount: item.likesCount ? item.likesCount + 1 : 1,
                          youLiked : true
                      }
                  }),
                  friends: state.public_user.friends,
                  following: state.public_user.following,
                  followers: state.public_user.followers,
                  multimedia: state.public_user.multimedia,
                  profile: state.public_user.profile,
                  relation: state.public_user.relation}
          }
      case 'PUBLIC_UPDATE_POST':
          return { ...state,
              public_user: {
                  posts: state.public_user.posts.map(item => {
                      if (item._id ===  action.data._id) {
                          return  action.data
                      } else {
                          return  item
                      }
                  }),
                  friends: state.public_user.friends,
                  following: state.public_user.following,
                  followers: state.public_user.followers,
                  multimedia: state.public_user.multimedia,
                  profile: state.public_user.profile,
                  relation: state.public_user.relation}
          }
      case 'FILTERED_VIDEO':
          return { ...state, featuredVideo: action.data }
    default:
      return { ...state }
  }
}
export default profile