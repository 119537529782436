import mock from '../mock'
const data = {
    // notification list data
    notificationsArray: [
        {
            id:1,
            type:'new_post',
            title : "added a post to his timeline",
            user_id :1,
            source_id :{
                title: 'The Best Features Coming to iOS and Web design',
                id: 1
            },
            feedback_by_user :{
            id: 1,
            fullName: 'Galen Slixby',
            avatar: ''
        },
            is_read : 0,
            is_cleared :0
        },
        {
            id:2,
            type:'new_post',
            title : "added a post to his timeline",
            user_id :1,
            source_id : {
                title: 'Latest Quirky Opening Sentence or Paragraph',
                id: 2
            },
            feedback_by_user : {
                id: 3,
                fullName: 'Marjory Sicely',
                avatar: require('@src/assets/images/avatars/1.png').default
            },
            is_read : 0,
            is_cleared :0
        },
        {
            id:3,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Marjory Sicely',
                avatar: require('@src/assets/images/avatars/1.png').default
            },
            is_read: 0,
            is_cleared :0
        },
        {
            id:4,
            type:'friend_requests',
            title : "sent you a friend request",
            user_id :1,
            source_id : {
                id: 1
            },
            feedback_by_user :{
                id: 2,
                fullName: 'Marjory Sicely',
                avatar: require('@src/assets/images/avatars/1.png').default,
                mutual_friends :2
            },
            is_read : 0,
            is_cleared :0
        },
        {
            id:5,
            type:'friend_requests',
            title : "sent you a friend request",
            user_id :1,
            source_id : {
                id: 2
            },
            feedback_by_user :{
                id: 3,
                fullName: 'Jillene Bellany',
                avatar: require('@src/assets/images/avatars/10.png').default,
                mutual_friends :25
            },
            is_read : 0,
            is_cleared :0
        },
        {
            id:6,
            type:'friend_requests',
            title : "sent you a friend request",
            user_id :1,
            source_id : {
                id: 4
            },
            feedback_by_user :{
                id: 5,
                fullName: 'Seth Hallam',
                avatar: require('@src/assets/images/avatars/3.png').default,
                mutual_friends :2
            },
            is_read : 0,
            is_cleared :0
        },
        {
            id:7,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Halsey Redmore',
                avatar: require('@src/assets/images/avatars/10.png').default
            },
            is_read: 0,
            is_cleared :0
        },
        {
            id:8,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Halsey Redmore',
                avatar: require('@src/assets/images/avatars/10.png').default
            },
            is_read: 0,
            is_cleared :0
        },
        {
            id:9,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Halsey Redmore',
                avatar: require('@src/assets/images/avatars/10.png').default
            },
            is_read: 0,
            is_cleared :0
        },
        {
            id:10,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Halsey Redmore',
                avatar: require('@src/assets/images/avatars/10.png').default
            },
            is_read: 0,
            is_cleared :0
        },
        {
            id:11,
            type:'new_post_comment',
            title : "added a comment on your post",
            user_id :1,
            source_id : {
                title: 'Share an Amazing and Shocking Fact or Statistic',
                id: 3
            },
            feedback_by_user : {
                id: 2,
                fullName: 'Halsey Redmore',
                avatar: require('@src/assets/images/avatars/10.png').default
            },
            is_read: 0,
            is_cleared :0
        }
    ]
}
mock.onGet('/users/notification/data').reply(() => [200, data.notificationsArray])
mock.onGet('/update/notification').reply(config => {
      const  id = config.id
    data.notificationsArray.filter((item, index) => {
        if (item.id === id) {
            item.is_read = 1
        }
        return item
    })
       return [200, data.notificationsArray]
})
mock.onGet('/clear/notification/count').reply(config => {
    const  user_id = config.id
    data.notificationsArray.filter((item, index) => {
        if (item.user_id === user_id) {
            item.is_cleared = 1
        }
        return item
    })
    return [200, data.notificationsArray]
})
//remove friend request notification
mock.onGet('/remove/request/notification').reply(config => {
    const  source_id = config.id
    const source_data = data.notificationsArray.find(item => item.source_id.id === source_id && item.type === 'friend_requests')
   if (source_data) {
       const request  = data.notificationsArray.reduce((p, item) => (item.id !== source_data.id && p.push(item), p), [])
       data.notificationsArray = request
   }
    return [200, data.notificationsArray]
})
