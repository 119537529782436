import mock from '../mock'
import {paginateArray} from "../utils"
// ** axiosInstance
import {axiosInstance} from '@configs/axiosinstance.js'
import moment  from 'moment'


const Log = (message, ...data) => console.log(message, ...data)
const FetchPosts = () => {
    Log('axiosInstance:get', '/post_flags')
    // return [200, data]
    return axiosInstance
        .get('/post_flags')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}

const sortData = (data, order) => {
    Log("data for filter", data)
    if (order === '1') {
        return data.reverse()
    } else {
        return  data
    }
}
mock.onGet('/posts/list/data').reply(config => FetchPosts().then(response => {
    const {data} = response
    const {perPage = 10, page = 1, order = null, type = '', date = ''} = config
    let comments = []

    const filteredData = sortData(data, order)
    filteredData.map((item) => {
        comments.push({id:item._id, title:item.title, user_id: item.user_id, type : 'post', date : item.created_at, name : item.post_users[0] !== undefined ? item.post_users[0].name : "", likesCount:item.likesCount, likesComment: item.likesComment, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user[0] !== undefined ? item.user[0].email : "", post_id:item._id, profile_photo: item.profile[0]?.profile_photo })
        if (item.detailedComments.length > 0) {
            item.detailedComments.map((item) => {
                comments.push({id:item._id, title:item.content, user_id: item.user_id, type : 'comment', date : item.created_at, name : item.users[0] !== undefined ? item.users[0].name : "", flagsCount: item.commentCounts !== undefined ? item.commentCounts : 0, email : item.users[0] !== undefined ? item.users[0].email : "",  post_id:item.post_id, profile_photo: item.comment_profile[0]?.profile_photo})
            })
        }
        return item
    })
    comments    = comments.filter((item) => item.flagsCount === 0)

    if (type !== "") {
         comments    = comments.filter((item) => item.type === type)
     }
    if (date !== "") {
        comments    = comments.filter((item) => moment(item.date).format("Y-M-D") === moment(date).format("Y-M-D"))
    }
    return [
        200,
        {
            posts: paginateArray(comments, perPage, page),
            total: comments.length,
             data
        }
    ]
}))

//save post data
const FetchOnlyPosts = (config) => {
    const skipdata = JSON.parse(config.data)
    Log('axiosInstance:get',  skipdata.skip)
    // return [200, data]
    return axiosInstance
        .get(`/posts/user/posts/${skipdata.skip}/${skipdata.limit}/${skipdata.is_profile}`)
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
//post data privacy
mock.onPost('/data/posts/list').reply(config => FetchOnlyPosts(config).then(response => {
    const {data} = response
    // alert(1111)
    // console.log(data)
    const user = JSON.parse(localStorage.getItem('userData'))
    const user_id = user.id
    const filter_data = []
    console.log(data)
    data.map((item) => {
        if (item.user_id === user_id) {
            filter_data.push(item)
        } else if (item.postPrivacy === '1' || item.postPrivacy === '2') {
            filter_data.push(item)
        }
    })
    return [
        200,
        {
            filter_data
        }
    ]
}))

//ban keyword data

const FetchBanKeywords = () => {
    Log('axiosInstance:get', '/posts')
    // return [200, data]
    return axiosInstance
        .get('/post_flags/get/keywords')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
mock.onGet('/data/bankeywords/list').reply(config => FetchBanKeywords().then(response => {
    const {data} = response
    const {q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null, order = -1} = config
    const queryLowered = q.toLowerCase()
    const filteredData = data.data.filter(keyword => (keyword.content.toLowerCase().includes(queryLowered)))
    const sorted = sortData(filteredData, order)
    return [
        200,
        {
            keywords: paginateArray(sorted, perPage, page),
            total: sorted.length,
            data : data.data
        }
    ]
}))
const FetchApprovedPosts = (status) => {
    Log('axiosInstance:get', '/post_flags')
    // return [200, data]
    return axiosInstance
        .get(`/post_flags/filter/${status}`)
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
mock.onGet('/approved/posts/list/data').reply(config => FetchApprovedPosts('approved').then(response => {
    const {data} = response
    const {perPage = 10, page = 1, order = null, type = '', date = ''} = config
    let comments = []
    const approvedposts = data.data.filter((item) => item.flagsPost.length > 0  && item.flagsPost[0].status === 'approved')
    const filteredData = sortData(approvedposts, order)
    filteredData.map((item) => {
        comments.push({id:item._id, title:item.title, user_id: item.user_id, type : 'post', date : item.created_at, name : item.post_users[0] !== undefined ? item.post_users[0].name : "", likesCount:item.likesCount, likesComment: item.likesComment, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user[0] !== undefined ? item.user[0].email : "", profile_photo: item.profile[0]?.profile_photo})
        if (item.detailedComments.length > 0) {
            item.detailedComments.map((item) => {
                comments.push({id:item._id, title:item.content, user_id: item.user_id, type : 'comment', date : item.created_at, name : item.users[0] !== undefined ? item.users[0].name : "", flagsCount: item.commentCounts !== undefined ? item.commentCounts : 0, email : item.users[0] !== undefined ? item.users[0].email : "", profile_photo: item.comment_profile[0]?.profile_photo})
            })
        }
        return item
    })
    comments    = comments.filter((item) => item.flagsCount !== 0)

    if (type !== "") {
        comments    = comments.filter((item) => item.type === type)
    }
    if (date !== "") {
        comments    = comments.filter((item) => moment(item.date).format("Y-M-D") === moment(date).format("Y-M-D"))
    }

    return [
        200,
        {
            posts: paginateArray(comments, perPage, page),
            total: comments.length,
            data : approvedposts
        }
    ]
}))
mock.onGet('/banned/posts/list/data').reply(config => FetchApprovedPosts('banned').then(response => {
    const {data} = response
    const {perPage = 10, page = 1, order = null, type = '', date = ''} = config
    let comments = []
    const approvedposts = data.data.filter((item) => item.flagsPost.length > 0  && item.flagsPost[0].status === 'banned')
    const filteredData = sortData(approvedposts, order)
    filteredData.map((item) => {
        comments.push({id:item._id, title:item.title, user_id: item.user_id, type : 'post', date : item.created_at, name : item.post_users[0] !== undefined ? item.post_users[0].name : "", likesCount:item.likesCount, likesComment: item.likesComment, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user[0] !== undefined ? item.user[0].email : "", reason : item.flagsPost[0].comments ? item.flagsPost[0].comments : "", profile_photo: item.profile[0]?.profile_photo })
        if (item.detailedComments.length > 0) {
            item.detailedComments.map((item) => {
                comments.push({id:item._id, title:item.content, user_id: item.user_id, type : 'comment', date : item.created_at, name : item.users[0] !== undefined ? item.users[0].name : "", flagsCount: item.commentCounts !== undefined ? item.commentCounts : 0, email : item.users[0] !== undefined ? item.users[0].email : "", reason : "", profile_photo: item.comment_profile[0]?.profile_photo })
            })
        }
        return item
    })
    comments    = comments.filter((item) => item.flagsCount !== 0)

    if (type !== "") {
        comments    = comments.filter((item) => item.type === type)
    }
    if (date !== "") {
        comments    = comments.filter((item) => moment(item.date).format("Y-M-D") === moment(date).format("Y-M-D"))
    }
    return [
        200,
        {
            posts: paginateArray(comments, perPage, page),
            total: comments.length,
            data : approvedposts
        }
    ]
}))

//prepared banned list array
mock.onGet('/data/bankeywords/array').reply(config => FetchBanKeywords().then(response => {
    const {data} = response
    const keywords = []
    data.data.map((item) => {
        keywords.push(item.content)
    })
    return [
        200,
        {
             keywords
        }
    ]
}))

const FetchFlagPosts = () => {
    Log('axiosInstance:get', '/post_flags')
    // return [200, data]
    return axiosInstance
        .get('/post_flags/review')
        .then(response => {
            Log('axiosInstance:response', response)
            return response
        })
}
mock.onGet('/posts/flags/data').reply(config => FetchFlagPosts().then(response => {
    const {data, post_comments, reply_comments} = response.data
    const {perPage = 10, page = 1, order = null, type = '', date = ''} = config
    let comments = []

      data.map((item) => {
        comments.push({id:item._id, title:item.title, user_id: item.user_id, type : 'post', date : item.created_at, name : item.post_users[0] !== undefined ? item.post_users[0].name : "", likesCount:item.likesCount, likesComment: item.likesComment, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user[0] !== undefined ? item.user[0].email : "", post_id:item._id, profile_photo: item.profile[0]?.profile_photo })
    })
    post_comments.map((item) => {
        comments.push({id:item._id, title:item.content, user_id: item.user_id, type : 'comment', date : item.created_at, name : item.user.name !== undefined ? item.user.name : "", likesCount:item.likesCount, likesComment: item.likesCount, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user.email !== undefined ? item.user.email : "", post_id:item.post_id, profile_photo: item.profile?.profile_photo })
    })
    reply_comments.map((item) => {
        comments.push({id:item._id, title:item.reply_content, user_id: item.user_id, type : 'reply_comment', date : item.created_at, name : item.user.name !== undefined ? item.user.name : "", likesCount:0, likesComment: 0, flagsCount:  item.flagsCount !== undefined ? item.flagsCount : 0, email : item.user.email !== undefined ? item.user.email : "", post_id:item.post_id, profile_photo: item.profile?.profile_photo })
    })
    comments    = comments.filter((item) => item.flagsCount === 0)

    if (type !== "") {
        comments    = comments.filter((item) => item.type === type)
    }
    if (date !== "") {
        comments    = comments.filter((item) => moment(item.date).format("Y-M-D") === moment(date).format("Y-M-D"))
    }
     comments = sortData(comments, order)
     const all_data = [...data, ...post_comments, ...reply_comments]
    return [
        200,
        {
            posts: paginateArray(comments, perPage, page),
            total: comments.length,
            all_data
        }
    ]
}))